<template>
  <v-container id="van" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showPersonError">
        {{ showErrorPerson }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showVehicleError">
        {{ showErrorEmail }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <!-- <v-col cols="6" md="6">
              <v-select
                :items="zipCodes"
                label="Select Zip Code"
                v-model="zipcode"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col> -->
            <v-col cols="6" md="6">
              <v-select
                :items="stores"
                label="Select Stores"
                item-text="name"
                item-value="_id"
                v-model="store_id"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Vehicle Type"
                class="purple-input"
                v-model="vehicle_type"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Vehicle Model"
                class="purple-input"
                v-model="vehicle_model"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Vehicle Number"
                class="purple-input"
                v-model="vehicle_number"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Vehicle Manufacturing Year"
                class="purple-input"
                v-model="vehicle_manufacturing_year"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Person Name"
                class="purple-input"
                v-model="person_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Timing"
                v-model="timing"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-file-input
                show-size
                label="Images"
                counter
                multiple
                truncate-length="15"
                @change="selectFile"
                v-model="files"
                :disabled="onlyView"
                accept="image/*"
              ></v-file-input>
              <div>
                <v-avatar v-for="image in images" :key="image">
                  <v-img
                    aspect-ratio="1"
                    :src="imageUrl + image"
                    :lazy-src="imageUrl + image"
                    @click="deleteImage(image)"
                    style="cursor: pointer"
                  />
                </v-avatar>
                <v-dialog v-model="imageDelete" max-width="500px">
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete image?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" depressed @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{ onlyView == true ? "Back" : editVan == true ? "Update" : "Submit" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      zipCodes: [],
      stores: [],
      store_id: "",
      editVan: null,
      // person_name: "",
      vehicle_type: "",
      vehicle_model: "",
      vehicle_number: "",
      vehicle_manufacturing_year: "",
      zipcode: "",
      timing: "",
      files: [],
      images: [],
      imageDelete: false,
      vanImageDelete: null,
      showVehicleError: false,
      showPersonError: false,
      sendFiles: [],
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any store"],
      onlyView: false,
      loading: false,
      imageUrl: process.env.VUE_APP_Image_Link,
    };
  },
  methods: {
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getZipCode() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "getZipCode")
        .then((response) => {
          if (response.status == 200) {
            this.zipCodes = response.data.getZipCode;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVanData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "van/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            // this.person_name = response.data.van.person_name;
            this.store_id = response.data.van.store_id;
            this.vehicle_number = response.data.van.vehicle_number;
            this.vehicle_type = response.data.van.vehicle_type;
            this.vehicle_model = response.data.van.vehicle_model;
            this.vehicle_manufacturing_year =
              response.data.van.vehicle_manufacturing_year;
            this.timing = response.data.van.timing;
            this.images = response.data.van.images;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectFile() {
      this.files.forEach((file) => {
        this.sendFiles.push(file);
      });
    },
    deleteImage(deleteImage) {
      if (this.onlyView == false) {
        this.imageDelete = true;
        this.vanImageDelete = deleteImage;
      }
    },
    deleteItemConfirm() {
      let data = { id: this.id, name: this.vanImageDelete };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "van/delete/image", data)
        .then((response) => {
          if (response.status == 200) {
            this.closeDelete();
            this.getVanData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDelete() {
      this.imageDelete = false;
    },
    submit() {
      this.showVehicleError = false;
      this.showPersonError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.append("id", this.id);
        // formData.append("person_name", this.person_name);
        formData.append("store_id", this.store_id);
        formData.append("vehicle_number", this.vehicle_number);
        formData.append("vehicle_type", this.vehicle_type);
        formData.append("vehicle_model", this.vehicle_model);
        formData.append("vehicle_manufacturing_year", this.vehicle_manufacturing_year);
        formData.append("timing", this.timing);
        for (let file of this.sendFiles) {
          formData.append("images[]", file, file.name);
        }

        if (this.onlyView) {
          this.$router.push({ name: "Vans" });
        } else if (this.editVan == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "van/update", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Vans",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.zipcode != null) {
                this.showErrorPerson = error.response.data.zipcode[0];
                this.showPersonError = true;
              }
              if (error.response.data.vehicle_number != null) {
                this.showVehicleError = true;
                this.showErrorEmail = error.response.data.vehicle_number[0];
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "van/create", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Vans",
                  params: { add: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.zipcode != null) {
                this.showErrorPerson = error.response.data.zipcode[0];
                this.showPersonError = true;
              }
              if (error.response.data.vehicle_number != null) {
                this.showVehicleError = true;
                this.showErrorEmail = error.response.data.vehicle_number[0];
              }
            });
        }
      }
    },
  },
  mounted() {
    this.getStoreList();
    if (this.id != undefined) {
      this.getVanData(this.id);
      this.editVan = true;
    } else {
      this.editVan = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>
